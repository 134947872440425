<template>
  <v-container>
    <back-toolbar title="login.registration"></back-toolbar>
    <form>
      <v-row dense class="mx-3">
        <v-col class="py-0" cols="12" sm="6">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            dense
            v-model="firstName"
            :error-messages="firstNameErrors"
            solo
            clearable
            :placeholder="$t('profile.firstName')"
            maxlength="50"
            @input="$v.firstName.$touch()"
            @blur="getFirstNameErrors()"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            dense
            v-model="lastName"
            :error-messages="lastNameErrors"
            solo
            clearable
            :placeholder="$t('profile.lastName')"
            maxlength="50"
            @input="$v.lastName.$touch()"
            @blur="getLastNameErrors()"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            dense
            v-model="email"
            :error-messages="emailErrors"
            solo
            clearable
            :placeholder="$t('profile.email')"
            maxlength="100"
            @input="email ? (email = email.trim()) : '', $v.email.$touch()"
            @blur="getEmailErrors()"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            dense
            v-model="pw1"
            :error-messages="pw1Errors"
            solo
            :placeholder="$t('profile.password')"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            maxlength="50"
            @click:append="showPassword = !showPassword"
            @input="$v.pw1.$touch()"
            @blur="getPw1Errors()"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            dense
            v-model="pw2"
            :error-messages="pw2Errors"
            solo
            :placeholder="$t('profile.confirmPassword')"
            :type="showConfirmPassword ? 'text' : 'password'"
            :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
            maxlength="50"
            @click:append="showConfirmPassword = !showConfirmPassword"
            @input="$v.pw2.$touch()"
            @blur="getPw2Errors()"
          ></v-text-field>
        </v-col>
      </v-row>
    </form>
    <v-row dense class="mx-3">
      <v-col cols="12">
        <v-spacer></v-spacer>
        <div class="Text-Color-Text-3---14-Right-Align">
          <router-link to="/login">
            {{ $t("login.alreadyAccount") }}
          </router-link>
        </div>
      </v-col>
    </v-row>
    <!-- class="transition-swing is a workaround for transitions not working on v-alert components in vuetify" -->
    <v-alert
      class="transition-swing mb-2"
      transition="scale-transition"
      type="error"
      v-model="error"
      >{{ errorMessage }}</v-alert
    >
    <v-row dense class="mx-3">
      <v-col cols="12" class="py-0">
        <v-btn
          :loading="createStatus === 'fetching'"
          @click="register"
          width="100%"
          height="48"
          depressed
          color="primary"
          class="gradient Heading-White-H4-18-Center"
        >
          {{ $t("login.registration") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="my-1">
      <v-col cols="12">
        <div class="Text-Dark-Text-1---18-Center">{{ $t("profile.or") }}</div>
      </v-col>
    </v-row>
    <v-layout row wrap>
      <v-flex xs2 offset-xs2>
        <v-img
          @click="$store.dispatch('session/facebookLogin')"
          src="@/assets/img/facebook.svg"
          width="48px"
          height="auto"
          class="social-login-button"
        ></v-img>
      </v-flex>
      <v-flex xs4>
        <v-img
          @click="$store.dispatch('session/googleLogin')"
          src="@/assets/img/google.svg"
          width="48px"
          height="auto"
          class="social-login-button"
        ></v-img>
      </v-flex>
      <v-flex xs2>
        <v-img
          @click="$store.dispatch('session/appleLogin')"
          src="@/assets/img/apple.svg"
          width="48px"
          height="auto"
          class="social-login-button"
        ></v-img>
        <v-spacer></v-spacer>
      </v-flex>
    </v-layout>
    <v-row dense class="mx-1 mb-8">
      <v-col cols="12">
        <div class="mt-5 Text-Dark-Text-3---14-Center">
          {{ $t("profile.acceptTerms") }}
          <a
            href="https://www.dynamic-deals.com/terms-of-use/"
            target="_system"
            @click="openTerms('https://www.dynamic-deals.com/terms-of-use/')"
          >
            {{ $t("profile.terms") }}
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import BackToolbar from "@/components/app/common/BackToolbar";
export default {
  mounted() {
    this.$store.commit("session/CLEAR_AUTH_ERROR");
  },
  validations: {
    firstName: {
      required,
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_ ]*$/),
    },
    lastName: {
      required,
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_ ]*$/),
    },
    email: { required, email },
    pw1: { required, minLength: minLength(8) },
    pw2: { required, sameAsPassword: sameAs("pw1") },
  },
  components: {
    BackToolbar,
  },
  data() {
    return {
      showErr: false,
      showPassword: false,
      showConfirmPassword: false,
      regData: false,
      firstName: "",
      lastName: "",
      email: "",
      pw1: "",
      pw2: "",
      firstNameErrors: [],
      lastNameErrors: [],
      emailErrors: [],
      pw1Errors: [],
      pw2Errors: [],
    };
  },
  methods: {
    openTerms(link) {
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
    async register() {
      this.$v.$touch();
      this.getFirstNameErrors();
      this.getLastNameErrors();
      this.getEmailErrors();
      this.getPw1Errors();
      this.getPw2Errors();

      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit("session/CLEAR_AUTH_ERROR");
      const user = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.pw1,
      };
      await this.$store.dispatch("session/createUser", user);
    },

    getFirstNameErrors() {
      const errors = [];

      if (!this.$v.firstName.$dirty) {
        this.firstNameErrors = errors;
        return false;
      }

      !this.$v.firstName.required &&
        errors.push(this.$t("profile.requireFirstname"));

      !this.$v.firstName.isValid &&
        errors.push(
          this.$t("profile.alphanumeric", {
            fieldName: "First name",
          })
        );

      this.firstNameErrors = errors;
      return errors.length > 0;
    },

    getLastNameErrors() {
      const errors = [];

      if (!this.$v.lastName.$dirty) {
        this.lastNameErrors = errors;
        return false;
      }

      !this.$v.lastName.required &&
        errors.push(this.$t("profile.requireLastname"));

      !this.$v.lastName.isValid &&
        errors.push(
          this.$t("profile.alphanumeric", {
            fieldName: "Last name",
          })
        );

      this.lastNameErrors = errors;
      return errors.length > 0;
    },

    getEmailErrors() {
      const errors = [];

      if (!this.$v.email.$dirty) {
        this.emailErrors = errors;
        return false;
      }

      !this.$v.email.email && errors.push(this.$t("profile.validMail"));

      !this.$v.email.required && errors.push(this.$t("profile.requireMail"));

      this.emailErrors = errors;
      return errors.length > 0;
    },

    getPw1Errors() {
      const errors = [];

      if (!this.$v.pw1.$dirty) {
        this.pw1Errors = errors;
        return false;
      }

      !this.$v.pw1.minLength && errors.push(this.$t("profile.atLeastChars"));

      !this.$v.pw1.required && errors.push(this.$t("profile.requirePassword"));

      this.pw1Errors = errors;
      return errors.length > 0;
    },

    getPw2Errors() {
      const errors = [];

      if (!this.$v.pw2.$dirty) {
        this.pw2Errors = errors;
        return false;
      }

      !this.$v.pw2.sameAsPassword &&
        errors.push(this.$t("profile.notIdentical"));

      !this.$v.pw2.required &&
        errors.push(this.$t("profile.requireConfirmPassword"));

      this.pw2Errors = errors;
      return errors.length > 0;
    },
  },
  computed: {
    ...mapState({
      createStatus: (state) => state.session.createStatus,
    }),
    error: {
      get() {
        return typeof this.$store.state.session.error === "string";
      },
    },
    errorMessage: {
      get() {
        return this.$store.state.session.error;
      },
    },
  },
};
</script>
<style scoped>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.social-login-button {
  margin: auto;
  cursor: pointer;
}
</style>
